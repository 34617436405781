import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SoldCarModal from "./SoldCarModal";

const SoldCars = () => {
  const [soldCars, setSoldCars] = useState([]);
  const [currentCar, setCurrentCar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchSoldCars = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "soldcars"));
        const soldCarData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSoldCars(soldCarData);
      } catch (error) {
        console.error("Error fetching sold cars:", error);
      }
    };

    fetchSoldCars();
  }, []);

  const handleDeleteCar = async (id) => {
    try {
      await deleteDoc(doc(db, "soldcars", id));
      setSoldCars((prevSoldCars) =>
        prevSoldCars.filter((car) => car.id !== id)
      );
    } catch (error) {
      console.error("Error deleting car:", error);
    }
  };

  const openModal = (car) => {
    setCurrentCar(car);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentCar(null);
  };

  const handleSaveCarData = async (updatedCar) => {
    try {
      await updateDoc(doc(db, "soldcars", updatedCar.id), updatedCar);
      setSoldCars((prevSoldCars) =>
        prevSoldCars.map((car) => (car.id === updatedCar.id ? updatedCar : car))
      );
    } catch (error) {
      console.error("Error updating car:", error);
    }
  };

  const handleAddReviewClick = (car) => {
    setCurrentCar(car);
    setIsModalOpen(true);
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Sold Cars Dashboard</h1>

      <div className="overflow-x-auto mb-8">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
          <thead className="bg-blue-600 text-white">
            <tr>
              {[
                "Car Name",
                "Price",
                "Seats Available",
                "Transmission",
                "Mileage",
                "Stock",
                "Year",
                "Color",
                "Kms Driven",
                "Number of Owners",
                "Negotiable",
                "Fuel Type",
                "Insurance",
                "Image",
                "Reviews",
                "Actions",
              ].map((header) => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-sm font-medium"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {soldCars.map((car) => (
              <tr key={car.id} className="border-b hover:bg-gray-50">
                <td className="px-6 py-4">{car.name}</td>
                <td className="px-6 py-4">{car.price}</td>
                <td className="px-6 py-4">{car.seats}</td>
                <td className="px-6 py-4">{car.transmission}</td>
                <td className="px-6 py-4">{car.mileage}</td>
                <td className="px-6 py-4">{car.stock}</td>
                <td className="px-6 py-4">{car.year}</td>
                <td className="px-6 py-4">{car.color}</td>
                <td className="px-6 py-4">{car.kmsDriven}</td>
                <td className="px-6 py-4">{car.numberOfOwners}</td>
                <td className="px-6 py-4">{car.negotiable}</td>
                <td className="px-6 py-4">{car.fuelType}</td>
                <td className="px-6 py-4">{car.insurance}</td>
                <td className="px-6 py-4">
                  {car.imageUrl ? (
                    <img
                      src={car.imageUrl}
                      alt={car.name}
                      className="w-24 h-auto"
                    />
                  ) : (
                    <span className="text-gray-500">No Image</span>
                  )}
                </td>
                <td className="px-6 py-4">
                  {car.reviews?.length > 0 ? (
                    <>
                      <div>
                        <h3 className="text-lg font-semibold">Reviews:</h3>
                        {car.reviews.map((review, index) => (
                          <p key={index} className="text-sm text-gray-600">
                            "{review.text}"{" "}
                            {/* Ensure review.text is a string */}
                          </p>
                        ))}
                      </div>
                      <div className="mt-2">
                        <p className="text-sm font-semibold">Ratings:</p>
                        {car.ratings && car.ratings.length > 0 ? (
                          <div className="flex items-center">
                            <span className="bg-[#4cc9f0] text-white px-3 py-1 rounded-full text-sm font-bold">
                              {(
                                car.ratings.reduce((a, b) => a + b, 0) /
                                car.ratings.length
                              ).toFixed(1)}
                              /5
                            </span>
                            <div className="flex ml-2">
                              {[...Array(5)].map((_, i) => (
                                <svg
                                  key={i}
                                  className={`w-4 h-4 ${
                                    i <
                                    car.ratings.reduce((a, b) => a + b, 0) /
                                      car.ratings.length
                                      ? "text-[#4cc9f0]"
                                      : "text-gray-300"
                                  }`}
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <p>No Ratings</p>
                        )}
                      </div>
                      {car.customerPhotos && car.customerPhotos.length > 0 && (
                        <div className="mt-2">
                          <h3 className="text-lg font-semibold">
                            Happy Customers:
                          </h3>
                          <img
                            src={car.customerPhotos[0]}
                            alt="Customer"
                            className="w-32 h-32 object-cover rounded-full border-2 border-gray-300"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={() => handleAddReviewClick(car)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      Add Review
                    </button>
                  )}
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => openModal(car)}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteCar(car.id)}
                    className="text-red-600 hover:text-red-800 ml-4"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <SoldCarModal
          car={currentCar}
          onClose={closeModal}
          onSave={handleSaveCarData}
        />
      )}
    </div>
  );
};

export default SoldCars;
