import React, { useState } from "react";
import CarList from "../Components/CarList";
import AddCarModal from "../Components/AddCarModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Carupload = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <div className="bg-blue-400 min-h-screen p-6">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-white text-3xl font-bold">Car Dashboard</h1>
          <button
            onClick={toggleModal}
            className="bg-white text-blue-400 px-4 py-2 rounded-lg flex items-center"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Car
          </button>
        </div>
        <CarList />
        {showModal && <AddCarModal onClose={toggleModal} />}
      </div>
    </div>
  );
};

export default Carupload;
