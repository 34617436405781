import React, { useState, useEffect } from "react";
import Hero from "../Components/Hero";
import Howitworks from "./Howitworks";
import Trending from "./Trending";
import Ad from "./Ad";
import Footer from "../Components/Footer";
import Reviews from "./Reviews";

import carGif from "../Assets/car.gif";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, 2000);

    const endTimer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
      clearTimeout(endTimer);
    };
  }, []);

  return (
    <div className="relative">
      {loading && (
        <div
          className={`fixed inset-0 bg-white z-50 flex items-center justify-center transition-transform duration-1000 ${
            startAnimation ? "translate-y-full" : ""
          }`}
        >
          <img src={carGif} alt="Loading..." className=" w-72 h-72" />
        </div>
      )}

      {!loading && (
        <div>
          <Hero />
          <Howitworks />
          <Trending />
          <Ad />
          <Reviews />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Home;
