import React from "react";

const Ad = () => {
  return (
    <div className="flex justify-center items-center bg-white">
      <div className="border border-black bg-blue-500 w-4/5 text-white p-4 hover:shadow-lg">
        <h1 className="text-2xl font-bold">
          YOUR DREAM CAR IS ONE CALL AWAY !!
        </h1>
        <h3 className="text-lg">Chat here or reach out to us at </h3>
        <p className="mt-2">
          PARK & SELL: Business only on COMMISSION basis from BOTH sides!
        </p>
      </div>
    </div>
  );
};

export default Ad;
