import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import Navbar from "../Components/Navbar";

const SingleProduct = () => {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const showVideo = useState(true); // State to manage video visibility

  useEffect(() => {
    const fetchCar = async () => {
      try {
        const carDoc = doc(db, "cars", id);
        const carSnapshot = await getDoc(carDoc);
        if (carSnapshot.exists()) {
          setCar(carSnapshot.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching car details: ", error);
      }
    };

    fetchCar();
  }, [id]);

  if (!car) return <p>Loading...</p>;

  return (
    <>
      <Navbar />
      <div className="max-w-6xl mx-auto px-4 py-8">
        {/* Breadcrumb */}
        <nav className="text-sm mb-4">
          <Link to="/" className="text-gray-500 hover:text-blue-500">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/cars" className="text-gray-500 hover:text-blue-500">
            Cars
          </Link>
          <span className="mx-2">/</span>
          <span className="text-gray-700">{car.name}</span>
        </nav>

        {/* Main content */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Image Section */}
          <div className="relative">
            <img
              src={car.imageUrl} // Only one image displayed
              alt={car.name}
              className="w-full h-auto object-cover rounded-lg shadow-lg"
            />
          </div>

          {/* Car Details */}
          <div>
            <h1 className="text-3xl font-bold mb-4">{car.name}</h1>

            {/* Specs Grid */}
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Price</span>
                <span className="block text-lg font-bold">₹{car.price}</span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Year</span>
                <span className="block text-lg font-bold">{car.year}</span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Mileage</span>
                <span className="block text-lg font-bold">
                  {car.mileage} kmpl
                </span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Kms Driven</span>
                <span className="block text-lg font-bold">
                  {car.kmsDriven} kms
                </span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">
                  Transmission
                </span>
                <span className="block text-lg font-bold">
                  {car.transmission}
                </span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Fuel Type</span>
                <span className="block text-lg font-bold">{car.fuelType}</span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Seats</span>
                <span className="block text-lg font-bold">{car.seats}</span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Color</span>
                <span className="block text-lg font-bold">{car.color}</span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Insurance</span>
                <span className="block text-lg font-bold">{car.insurance}</span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Owners</span>
                <span className="block text-lg font-bold">
                  {car.numberOfOwners}
                </span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Stock</span>
                <span className="block text-lg font-bold">{car.stock}</span>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                <span className="block text-sm font-semibold">Negotiable</span>
                <span className="block text-lg font-bold">
                  {car.negotiable ? "Yes" : "No"}
                </span>
              </div>
            </div>

            {/* Features & Safety */}
            <div className="bg-white shadow-lg rounded-lg p-4 mb-4">
              <h2 className="text-lg font-semibold mb-2">Features & Safety</h2>
              <p>{car.features}</p>
              <p className="mt-2">{car.safety}</p>
            </div>
          </div>
        </div>

        {/* YouTube Video */}
        {showVideo && car.youtubeLink && (
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Watch Video</h2>
            <iframe
              width="100%"
              height="500"
              src={`https://www.youtube-nocookie.com/embed/${new URL(
                car.youtubeLink
              ).searchParams.get("v")}`}
              title="YouTube video"
              frameBorder="0"
              allowFullScreen
              className="rounded-lg shadow-lg"
            ></iframe>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleProduct;
