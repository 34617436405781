import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faDollarSign,
  faClipboardList,
  faDashboard,
} from "@fortawesome/free-solid-svg-icons";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config"; // Adjust the path as necessary
import Soldcars from "./Soldcars";
import CMS from "./CMS";
import CarList from "../Components/CarList";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { useNavigate } from "react-router-dom"; // To navigate to login page

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState("dashboard");
  const [salesData, setSalesData] = useState([]);
  const [marketShareData, setMarketShareData] = useState([]);
  const [luxuryCars, setLuxuryCars] = useState([]);
  const COLORS = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];
  const navigate = useNavigate(); // For navigation to login page

  // Check for token in localStorage
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      // If token is missing, redirect to login
      alert("Please log in to access the dashboard.");
      navigate("/login"); // Redirect to the login page
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const soldCarsCollection = collection(db, "soldcars");
      const soldCarsSnapshot = await getDocs(soldCarsCollection);
      const soldCarsData = soldCarsSnapshot.docs.map((doc) => doc.data());

      processChartData(soldCarsData);
    };

    fetchData();
  }, []);

  const processChartData = (soldCarsData) => {
    const salesByModel = soldCarsData.reduce((acc, car) => {
      const existing = acc.find((item) => item.name === car.name);
      if (existing) {
        existing.sales += 1;
      } else {
        acc.push({ name: car.name, sales: 1, price: car.price });
      }
      return acc;
    }, []);

    const marketShareData = salesByModel.map((car) => ({
      name: car.name,
      value: car.sales,
      price: car.price,
    }));

    setSalesData(salesByModel);
    setMarketShareData(marketShareData);

    const luxuryCars = soldCarsData
      .filter((car) => parseFloat(car.price.replace(/[^0-9.-]+/g, "")) > 50000)
      .map((car) => ({
        id: car.id,
        name: car.name,
        price: car.price,
        sales: car.sales || 1,
      }));

    setLuxuryCars(luxuryCars);
  };

  const renderDashboard = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
      <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">
          Sales by Model
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={salesData}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="sales" fill="#36A2EB" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">
          Market Share
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={marketShareData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {marketShareData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="col-span-1 md:col-span-2 lg:col-span-2 bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">
          Higher-End Car Sales
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-3 px-4 text-left">Model</th>
                <th className="py-3 px-4 text-left">Price</th>
                <th className="py-3 px-4 text-left">Units Sold</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {luxuryCars.map((car) => (
                <tr key={car.id} className="border-b hover:bg-gray-100">
                  <td className="py-3 px-4">{car.name}</td>
                  <td className="py-3 px-4">{car.price}</td>
                  <td className="py-3 px-4">{car.sales}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const renderComponent = () => {
    switch (activeComponent) {
      case "caruploaddashboard":
        return <CarList />;
      case "soldcars":
        return <Soldcars />;
      case "contentmanagementsystem":
        return <CMS />;
      case "dashboard":
      default:
        return renderDashboard();
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-gray-800 text-white shadow-md flex-shrink-0">
        <div className="p-4">
          <h2 className="text-2xl font-bold">Insights</h2>
        </div>
        <nav className="mt-4">
          {[
            { icon: faDashboard, text: "Dashboard" },
            { icon: faCar, text: "Car Upload Dashboard" },
            { icon: faDollarSign, text: "Sold Cars" },
            { icon: faClipboardList, text: "Content Management System" },
          ].map(({ icon, text }) => (
            <button
              key={text}
              onClick={() =>
                setActiveComponent(text.toLowerCase().replace(/ /g, ""))
              }
              className={`flex items-center w-full py-2 px-4 text-left hover:bg-gray-700 focus:outline-none ${
                activeComponent === text.toLowerCase().replace(/ /g, "")
                  ? "bg-gray-700"
                  : ""
              }`}
            >
              <FontAwesomeIcon icon={icon} className="mr-3" />
              {text}
            </button>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6">{renderComponent()}</div>
    </div>
  );
};

export default Dashboard;
