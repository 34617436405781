import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";
import Navbar from "./Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import carPlaceholder from "../Assets/car.png";

const Hero = () => {
  const [heroContent, setHeroContent] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const fetchHeroContent = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "heroContent"));
        const content = querySnapshot.docs.map((doc) => doc.data())[0];
        console.log("Fetched hero content:", content);
        setHeroContent(content);
      } catch (error) {
        console.error("Error fetching hero content: ", error);
      }
    };


    fetchHeroContent();
  }, []);

  if (!heroContent ) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gradient-to-br from-white to-violet-200 md:min-h-screen relative overflow-hidden">
      <Navbar />
      <div className="container mx-auto px-4 py-12 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 mb-8 lg:mb-0" data-aos="fade-right">
          <h1 className="text-4xl lg:text-5xl font-bold mb-4 text-gray-800 leading-tight">
            {heroContent.heading}
            <br />
            Buy or Sell with <span className="text-blue-400">Ease</span>
          </h1>
          <p className="text-gray-600 mb-6 leading-relaxed">
            {heroContent.subheading}
          </p>
          <button className="relative bg-gray-800 text-white px-6 py-3 rounded-md overflow-hidden group">
            <Link to={"/aboutus"} className="relative z-10">
              {heroContent.buttonText}
            </Link>
            <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-600 opacity-30 transform scale-125 transition-transform duration-500 ease-in-out group-hover:scale-150"></span>
            <span className="absolute inset-0 border-2 border-white rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
          </button>
        </div>
        <div className="lg:w-1/2 relative" data-aos="fade-left">
          <img
            src={heroContent.image || carPlaceholder}
            className="w-full transform hover:scale-105 transition-transform duration-300"
            alt={heroContent.name || "Luxury Car"}
          />
          <div
            className="absolute top-4 right-4 bg-white rounded-full px-3 py-1 flex items-center shadow-md"
            data-aos="fade-down"
          >
            <span className="mr-2">{heroContent.type || "Automatic"}</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          <div
            className="absolute bottom-4 left-4 bg-white rounded-full px-3 py-1 flex items-center shadow-md"
            data-aos="fade-up"
          >
            <svg
              className="w-4 h-4 mr-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              ></path>
            </svg>
            <span>{heroContent.seats || "4"} Seats</span>
          </div>
          <div
            className="absolute bottom-4 right-4 bg-red-500 text-white rounded-md px-3 py-1 shadow-lg"
            data-aos="fade-left"
          >
            {heroContent.fuel || "Petrol"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
