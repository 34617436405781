import React from "react";
import logo from "../Assets/icaars.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const handleCall = () => {
    window.location.href = "tel:9843442222";
  };

  const handleLocation = () => {
    window.open(
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.217271597577!2d76.9147524!3d11.0223188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85f0a70e27f4b%3A0xd1a56178e738074e!2sI%20caars!5e0!3m2!1sen!2sin!4v1723275119436!5m2!1sen!2sin",
      "_blank"
    );
  };

  return (
    <>
      <button
        className="text-gray-600 hover:text-gray-900"
        onClick={handleCall}
      >
        <h1 className=" text-gray-500 ml-10 mt-2">Call Us +91 9843442222</h1>
      </button>

      <hr />
      <nav className="bg-white bg-opacity-90 shadow-sm px-6 py-4">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div className="flex-shrink-0">
            <img src={logo} alt="HV Logo" className=" w-32 rounded-full" />
          </div>

          <div className="hidden md:block">
            <ul className="flex space-x-8">
              <li>
                <a href="/" className="text-gray-600 hover:text-gray-900">
                  Home
                </a>
              </li>
              <li>
                <a href="aboutus" className="text-gray-600 hover:text-gray-900">
                  About us
                </a>
              </li>
              <li>
                <a
                  href="vehicles"
                  className="text-gray-600 hover:text-gray-900"
                >
                  Vehicle Listings
                </a>
              </li>
            </ul>
          </div>

          <div className="flex items-center justify-center space-x-4">
            <div className="flex justify-center space-x-4 mt-4">
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={handleLocation}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} className="h-6 w-6" />
              </button>
              <a
                href="https://wa.me/c/919843442222"
                className="text-gray-600 hover:text-sky-400"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="xl" />
              </a>
              <a
                href="https://www.instagram.com/_i_caars_?igsh=dmhtbDYwMmN2dHZl"
                className="text-gray-600 hover:text-sky-400"
              >
                <FontAwesomeIcon icon={faInstagram} size="xl" />
              </a>
              <a
                href="https://youtube.com/@icaarscbe?si=D7r5lLaezrLzr9qI"
                className="text-gray-600 hover:text-sky-400"
              >
                <FontAwesomeIcon icon={faYoutube} size="xl" />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
