import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Dashboard from "./Pages/Dashboard";
import Carupload from "./Pages/Carupload";
import Soldcars from "./Pages/Soldcars";
import VehicleListing from "./Pages/VehicleListing";
import SingleProduct from "./Pages/SingleProduct";
import Aboutus from "./Pages/Aboutus";
import Contact from "./Pages/Contact";
import CMS from "./Pages/CMS";
import Signin from "./Pages/Signin";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/caruploaddashboard" element={<Carupload />} />
          <Route path="/admin/contentmanagementsystem" element={<CMS />} />
          <Route path="/admin/soldcars" element={<Soldcars />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/vehicles" element={<VehicleListing />} />
          <Route path="/vehicles/:id" element={<SingleProduct />} />
          <Route path="/login" element={<Signin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
