import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCogs, faGasPump } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";

const Trending = () => {
  const [carData, setCarData] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    fetchCarData();
  }, []);

  const fetchCarData = async () => {
    try {
      const carsCollection = collection(db, "cars");
      const carSnapshot = await getDocs(carsCollection);
      const carList = carSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCarData(carList);
    } catch (error) {
      console.error("Error fetching car data: ", error);
    }
  };

  const handleRentNow = (car) => {
    const phoneNumber = "9843442222";
    const message = encodeURIComponent(
      `Hi, I'm interested in buying the following car:\n\nName: ${car.name}\nPrice: ${car.price}\n\nPlease let me know the next steps.`
    );
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="py-10 bg-blue-50">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-4">
          <span className="text-blue-500">Icaars </span> Stocks
        </h2>
        <p className="text-center text-gray-600 mb-10">Current stock items</p>
        <div className="flex flex-wrap justify-center gap-8">
          {carData.map((car) => (
            <div
              key={car.id}
              className="bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 overflow-hidden"
              data-aos="fade-up"
              style={{ width: "300px", height: "auto" }}
            >
              <div className="relative w-full h-40">
                <img
                  src={car.imageUrl}
                  alt={car.name}
                  className="object-contain w-full h-full"
                />
              </div>
              <div className="p-4">
                <div className="flex mb-4 justify-between">
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{car.name}</h3>
                    <p className="text-gray-600">{car.description}</p>
                  </div>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-blue-500 mr-2"
                    />
                    <span>{car.seats} Seats</span>
                  </div>
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCogs}
                      className="text-blue-500 mr-2"
                    />
                    <span>{car.transmission}</span>
                  </div>
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faGasPump}
                      className="text-blue-500 mr-2"
                    />
                    <span>{car.fuelType}</span>
                  </div>
                </div>
                <button
                  onClick={() => handleRentNow(car)}
                  className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Buy Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trending;
