import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faDollarSign,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css"; // import AOS styles

const Howitworks = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1000ms
  }, []);

  return (
    <div className="flex flex-col items-center justify-center py-5 md:py-10 bg-gradient-to-r from-blue-50 to-white">
      <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-800 text-center">
        How It <span className="text-blue-500">Works</span>
      </h2>
      <p className="text-gray-600 mb-10 text-center">
        Follow These Steps to Buy or Sell Your Car
      </p>
      <div className="flex flex-col md:flex-row flex-wrap justify-center gap-8 mb-10">
        <div className="w-full md:w-1/3">
          <div
            className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg border border-gray-300 transition-transform transform hover:scale-105"
            data-aos="fade-up"
          >
            <div className="text-blue-500 mb-4">
              <FontAwesomeIcon icon={faSearch} size="3x" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-800 text-center">
              Explore Listings
            </h3>
            <p className="text-gray-600 text-center">
              Browse through a wide range of cars available for sale or check
              out the cars you want to sell.
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/3">
          <div
            className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg border border-gray-300 transition-transform transform hover:scale-105"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="text-blue-500 mb-4">
              <FontAwesomeIcon icon={faDollarSign} size="3x" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-800 text-center">
              Set Your Price
            </h3>
            <p className="text-gray-600 text-center">
              For buyers, find the best deals by comparing prices. For sellers,
              set a competitive price for your car.
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/3">
          <div
            className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg border border-gray-300 transition-transform transform hover:scale-105"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="text-blue-500 mb-4">
              <FontAwesomeIcon icon={faHandshake} size="3x" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-800 text-center">
              Finalize the Deal
            </h3>
            <p className="text-gray-600 text-center">
              Complete the transaction and arrange for the delivery or pickup of
              the car. Enjoy your new ride or cash from the sale!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Howitworks;
