import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { doc, getDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBgscNUzfLELmbTAx28FaziIh9Ph-t-T7g",
  authDomain: "icaars-d2a38.firebaseapp.com",
  projectId: "icaars-d2a38",
  storageBucket: "icaars-d2a38.appspot.com",
  messagingSenderId: "860806123377",
  appId: "1:860806123377:web:c2992eb1de4919537cb0d0",
  measurementId: "G-3Q547KK67H",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage, doc, getDoc, setDoc };
