import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase.config";
import AddCarModal from "./AddCarModal"; // Make sure the path is correct

const CarList = () => {
  const [cars, setCars] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCar, setCurrentCar] = useState(null);

  useEffect(() => {
    const fetchCars = async () => {
      const querySnapshot = await getDocs(collection(db, "cars"));
      const carData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCars(carData);
    };

    fetchCars();
  }, []);

  const handleSoldChange = async (car) => {
    try {
      await setDoc(doc(db, "soldcars", car.id), car);
      await deleteDoc(doc(db, "cars", car.id));
      setCars((prevCars) => prevCars.filter((c) => c.id !== car.id));
    } catch (error) {
      console.error("Error moving car to soldcars:", error);
    }
  };

  const handleDeleteCar = async (id) => {
    try {
      await deleteDoc(doc(db, "cars", id));
      setCars((prevCars) => prevCars.filter((car) => car.id !== id));
    } catch (error) {
      console.error("Error deleting car:", error);
    }
  };

  const handleUpdateCar = (car) => {
    setCurrentCar(car);
    setIsModalOpen(true);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentCar(null);
  };

  return (
    <div className="p-6 bg-gray-100">
      <h1 className="text-2xl font-semibold mb-4">Car Listings</h1>
      <button
        onClick={openModal}
        className="bg-blue-600 text-white px-4 py-2 rounded mb-4"
      >
        Add Car
      </button>
      <div className="overflow-x-auto">
        <table className="w-full bg-white border-collapse rounded-lg shadow-md">
          <thead className="bg-blue-600 text-white">
            <tr>
              {[
                "Car Name",
                "Price",
                "Seats Available",
                "Transmission",
                "Mileage",
                "Stock",
                "Year",
                "Color",
                "Kms Driven",
                "Number of Owners",
                "Negotiable",
                "Fuel Type",
                "Insurance",
                "Image",
                "Sold",
                "Actions",
              ].map((header) => (
                <th key={header} className="p-4 border-b text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {cars.map((car) => (
              <tr key={car.id} className="odd:bg-gray-50 even:bg-gray-100">
                <td className="p-4 border-b">{car.name}</td>
                <td className="p-4 border-b">₹{car.price}</td>
                <td className="p-4 border-b">{car.seats}</td>
                <td className="p-4 border-b">{car.transmission}</td>
                <td className="p-4 border-b">{car.mileage}</td>
                <td className="p-4 border-b">{car.stock}</td>
                <td className="p-4 border-b">{car.year}</td>
                <td className="p-4 border-b">{car.color}</td>
                <td className="p-4 border-b">{car.kmsDriven}</td>
                <td className="p-4 border-b">{car.numberOfOwners}</td>
                <td className="p-4 border-b">{car.negotiable}</td>
                <td className="p-4 border-b">{car.fuelType}</td>
                <td className="p-4 border-b">{car.insurance}</td>
                <td className="p-4 border-b">
                  {car.imageUrl ? (
                    <img
                      src={car.imageUrl}
                      alt={car.name}
                      className="w-24 h-auto"
                    />
                  ) : (
                    <span className="text-gray-500">No Image</span>
                  )}
                </td>
                <td className="p-4 border-b">
                  <input
                    type="checkbox"
                    onChange={() => handleSoldChange(car)}
                  />
                </td>
                <td className="p-4 border-b">
                  <button
                    onClick={() => handleUpdateCar(car)}
                    className="bg-blue-600 text-white px-3 py-1 rounded mr-2"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDeleteCar(car.id)}
                    className="bg-red-600 text-white px-3 py-1 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <AddCarModal
          onClose={closeModal}
          car={currentCar}
          onSave={async (updatedCar) => {
            if (updatedCar) {
              try {
                await updateDoc(doc(db, "cars", updatedCar.id), updatedCar);
                setCars((prevCars) =>
                  prevCars.map((car) =>
                    car.id === updatedCar.id ? updatedCar : car
                  )
                );
              } catch (error) {
                console.error("Error updating car:", error);
              }
            }
            closeModal();
          }}
        />
      )}
    </div>
  );
};

export default CarList;
