import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import carGif from "../Assets/car.gif";
import { db } from "../firebase.config"; // Import Firestore instance
import { doc, getDoc } from "firebase/firestore";

const Aboutus = () => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [startCloseAnimation, setStartCloseAnimation] = useState(false);
  const [startOpenAnimation, setStartOpenAnimation] = useState(false);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, "aboutuscontent", "currentContent"); // Adjust doc ID as needed
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setContent(docSnap.data());
        } else {
          console.error("No such document!");
        }

        setLoading(false);
        setStartOpenAnimation(true);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();

    const closeTimer = setTimeout(() => {
      setStartCloseAnimation(true);
    }, 500);

    const gifTimer = setTimeout(() => {
      setLoading(false);
      setStartOpenAnimation(true);
    }, 2500);

    return () => {
      clearTimeout(closeTimer);
      clearTimeout(gifTimer);
    };
  }, []);

  return (
    <div className="relative">
      <div
        className={`fixed inset-0 bg-white z-50 flex items-center justify-center transition-transform duration-1000 ${
          startCloseAnimation ? "translate-y-full" : ""
        } ${startOpenAnimation ? "-translate-y-full" : ""}`}
      >
        {loading && <img src={carGif} alt="Loading..." className="w-72 h-72" />}
      </div>
      {!loading && startOpenAnimation && content && (
        <>
          <Navbar />
          <div id="about" className="relative bg-gray-100 overflow-hidden">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col lg:flex-row items-center lg:items-start">
                <div className="lg:w-1/2 mb-8 lg:mb-0">
                  <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
                    {content.title}
                  </h2>
                  <p className="text-lg text-gray-600">{content.description}</p>
                </div>
                <div className="lg:w-1/2 flex justify-center">
                  <img
                    src={content.imageUrl}
                    className="w-full max-w-md rounded-lg shadow-lg"
                    alt="About Us"
                  />
                </div>
              </div>
            </div>
          </div>
          <section className="bg-white py-12">
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold text-gray-900">Why Us?</h2>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex flex-wrap justify-center">
                {content.features.map((item, index) => (
                  <div key={index} className="p-4 md:w-1/3 lg:w-1/4">
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                      <div className="flex justify-center mb-4">
                        <img
                          src={item.image}
                          className="w-24 h-24 object-contain"
                          alt={item.title}
                        />
                      </div>
                      <h3 className="text-xl font-semibold text-gray-800 mb-2">
                        {item.title}
                      </h3>
                      <p className="text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Aboutus;
