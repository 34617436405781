import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../Assets/icaars.png";

const Footer = () => {
  return (
    <footer className="bg-white py-12 relative">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="col-span-1 md:col-span-2"
          >
            <img src={logo} alt="LV Logo" className="w-24 mb-4" />
            <p className="text-gray-600 text-sm max-w-md">
              A Brand That Symbolizes The Automobile Industry's Pleasure And
              Enthusiasm. We Collaborate With Celebrities, Artists, Sportsmen,
              And Social Media Influencers You Love.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="space-y-4"
          >
            <h3 className="text-sky-400 font-semibold mb-2">CONTACT</h3>
            <p className="text-gray-600 text-sm">
              check post back side, 11, New Thillai Nagar Main Rd, Vadavalli,
              Mullai Nagar, Coimbatore, Tamil Nadu 641041
            </p>
            <p className="text-gray-600 text-sm">contact@icaars.com</p>
            <p className="text-gray-600 text-sm">+91 9843442222</p>

            {/* Social Media Icons */}
            <div className="flex justify-center space-x-4 mt-4">
              <a
                href="https://wa.me/c/919843442222"
                className="text-gray-600 hover:text-sky-400"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/_i_caars_?igsh=dmhtbDYwMmN2dHZl"
                className="text-gray-600 hover:text-sky-400"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://youtube.com/@icaarscbe?si=D7r5lLaezrLzr9qI"
                className="text-gray-600 hover:text-sky-400"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </div>
            {/* Google Map Section */}
            <div className="mt-12 text-center">
              <h3 className="text-sky-400 font-semibold mb-4">
                Find Us On The Map
              </h3>
              <div className="relative mx-auto" style={{ maxWidth: "400px" }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.217271597577!2d76.9147524!3d11.0223188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85f0a70e27f4b%3A0xd1a56178e738074e!2sI%20caars!5e0!3m2!1sen!2sin!4v1723275119436!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Maps"
                  className="rounded-lg shadow-lg"
                ></iframe>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="space-y-2"
          >
            <h3 className="text-sky-400 font-semibold mb-2">USEFUL LINKS</h3>
            <ul className="text-gray-600 text-sm">
              <li>
                <a href="/" className="hover:text-sky-400 transition-colors">
                  Home
                </a>
              </li>
              <li>
                <a href="/aboutus" className="hover:text-sky-400 transition-colors">
                  About Us
                </a>
              </li>
              <li>
                <a href="/vehicles" className="hover:text-sky-400 transition-colors">
                  Vehicle List
                </a>
              </li>
            </ul>
          </motion.div>
        </div>

        <div className="border-t border-gray-200 my-8"></div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="text-center text-gray-500 text-xs"
        >
          © icaars.com All Rights Reserved.
        </motion.div>
      </div>

      <div className="absolute inset-0 z-[-1] opacity-5">
        <img
          src={logo}
          alt="World Map"
          className="w-full h-full object-cover"
        />
      </div>
    </footer>
  );
};

export default Footer;
