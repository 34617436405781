import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  getDocs,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase.config"; // Firebase Firestore instance

const CMS = () => {
  // State for Hero section content
  const [heroContent, setHeroContent] = useState({
    heading: "",
    subheading: "",
    buttonText: "",
    automaticText: "",
    seatsText: "",
    fuelText: "",
    image: "",
  });

  // State for About Us content
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    imageUrl: "",
    features: [],
  });

  // Fetch and set current About Us content from Firestore
  useEffect(() => {
    const fetchAboutUsContent = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "aboutuscontent"));
        querySnapshot.forEach((doc) => {
          setFormData(doc.data());
        });
      } catch (error) {
        console.error("Error fetching About Us content:", error);
      }
    };

    fetchAboutUsContent();
  }, []);

  // Fetch existing Hero content from Firestore
  useEffect(() => {
    const fetchHeroContent = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "heroContent"));
        const existingContent = querySnapshot.docs.map((doc) => doc.data())[0];
        if (existingContent) {
          setHeroContent(existingContent);
        }
      } catch (error) {
        console.error("Error fetching hero content:", error);
      }
    };

    fetchHeroContent();
  }, []);

  // Handle change for About Us content
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle change for Features in About Us
  const handleFeatureChange = (index, e) => {
    const { name, value } = e.target;
    const newFeatures = [...formData.features];
    newFeatures[index] = { ...newFeatures[index], [name]: value };
    setFormData({ ...formData, features: newFeatures });
  };

  // Add new feature
  const addFeature = () => {
    setFormData({
      ...formData,
      features: [
        ...formData.features,
        { title: "", description: "", image: "" },
      ],
    });
  };

  // Remove feature by index
  const removeFeature = (index) => {
    const newFeatures = formData.features.filter((_, i) => i !== index);
    setFormData({ ...formData, features: newFeatures });
  };

  // Submit About Us content to Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "aboutuscontent", "currentContent"); // Change "currentContent" to a specific ID if needed
      await setDoc(docRef, formData, { merge: true }); // Merge will update the document without overwriting
      alert("About Us content updated successfully!");
    } catch (error) {
      console.error("Error updating About Us content:", error);
    }
  };

  // Handle change for Hero content
  const handleHeroChange = (e) => {
    const { name, value } = e.target;
    setHeroContent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Submit Hero content to Firestore
  const handleHeroSubmit = async (e) => {
    e.preventDefault();
    try {
      const q = collection(db, "heroContent");
      const querySnapshot = await getDocs(q);
      const existingDocs = querySnapshot.docs.map((doc) => doc.id);

      if (existingDocs.length > 0) {
        // Update existing document
        const docRef = doc(db, "heroContent", existingDocs[0]);
        await updateDoc(docRef, heroContent);
        alert("Hero content updated successfully!");
      } else {
        // Add new document
        await addDoc(collection(db, "heroContent"), heroContent);
        alert("Hero content added successfully!");
      }

      // Reset form
      setHeroContent({
        heading: "",
        subheading: "",
        buttonText: "",
        automaticText: "",
        seatsText: "",
        fuelText: "",
        image: "",
      });
    } catch (error) {
      console.error("Error submitting Hero content:", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      {/* CMS for Hero Section */}
      <h1 className="text-2xl font-bold mb-4">CMS - Edit Hero Section</h1>
      <form onSubmit={handleHeroSubmit}>
        {Object.keys(heroContent).map((key) => (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 capitalize">
              {key.replace(/([A-Z])/g, " $1")}
            </label>
            <input
              type="text"
              name={key}
              value={heroContent[key]}
              onChange={handleHeroChange}
              className="w-full px-3 py-2 border rounded"
              placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
              required
            />
          </div>
        ))}
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Update Hero Section
        </button>
      </form>

      <hr className="my-8" />

      {/* CMS for About Us Page */}
      <h1 className="text-2xl font-bold mb-4">Edit About Us Page</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Title</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Main Image URL</label>
          <input
            type="text"
            name="imageUrl"
            value={formData.imageUrl}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Features</label>
          {formData.features.map((feature, index) => (
            <div
              key={index}
              className="mb-4 p-2 border border-gray-300 rounded"
            >
              <label className="block text-gray-700">Feature Title</label>
              <input
                type="text"
                name="title"
                value={feature.title}
                onChange={(e) => handleFeatureChange(index, e)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
              <label className="block text-gray-700 mt-2">Description</label>
              <textarea
                name="description"
                value={feature.description}
                onChange={(e) => handleFeatureChange(index, e)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
              <label className="block text-gray-700 mt-2">Image URL</label>
              <input
                type="text"
                name="image"
                value={feature.image}
                onChange={(e) => handleFeatureChange(index, e)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
              <button
                type="button"
                onClick={() => removeFeature(index)}
                className="mt-2 bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
              >
                Remove Feature
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addFeature}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Add Feature
          </button>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Save About Us Content
        </button>
      </form>
    </div>
  );
};

export default CMS;
