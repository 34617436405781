import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase.config";

const AddCarModal = ({ onClose }) => {
  const [car, setCar] = useState({
    name: "",
    price: "",
    seats: "",
    transmission: "",
    gears: "",
    mileage: "",
    stock: "",
    imageUrl: "",
    year: "",
    color: "",
    kmsDriven: "",
    fuelType: "",
    insurance: "",
    numberOfOwners: "",
    negotiable: "",
    safety: "",
    features: "",
    youtubeLink: "", // Add this line
  });
  const [image, setImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCar({ ...car, [name]: value });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = "";
      if (image) {
        const imageRef = ref(storage, `car-images/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Log the data to verify its format
      console.log("Data to be added:", { ...car, imageUrl });

      await addDoc(collection(db, "cars"), { ...car, imageUrl });
      onClose();
    } catch (error) {
      console.error("Error adding car: ", error.message);
    }
  };

  const isNumber = (value) => {
    return /^\d*$/.test(value);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-bold mb-6">Add New Car</h2>
        <form
          onSubmit={async (e) => {
            setIsSubmitting(true);
            await handleSubmit(e);
            setIsSubmitting(false);
            setShowSuccess(true);
            setTimeout(() => setShowSuccess(false), 3000);
          }}
          className="grid grid-cols-1 md:grid-cols-2 gap-6"
        >
          {/* First Column */}
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="name">
                Car Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={car.name}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="price">
                Price
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                  ₹
                </span>
                <input
                  type="text"
                  id="price"
                  name="price"
                  value={car.price}
                  onChange={(e) => {
                    if (isNumber(e.target.value)) handleChange(e);
                  }}
                  className="w-full p-2 pl-8 border border-gray-300 rounded"
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="seats">
                Seats Available
              </label>
              <input
                type="text"
                id="seats"
                name="seats"
                value={car.seats}
                onChange={(e) => {
                  if (isNumber(e.target.value)) handleChange(e);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div>
              <label className="block text-gray-700 mb-2" htmlFor="safety">
                Safety Features
              </label>
              <textarea
                id="safety"
                name="safety"
                value={car.safety}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                rows="4"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="features">
                Features
              </label>
              <textarea
                id="features"
                name="features"
                value={car.features}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                rows="4"
              />
            </div>

            {/* YouTube Link Field */}
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="youtubeLink">
                YouTube Link
              </label>
              <input
                type="text"
                id="youtubeLink"
                name="youtubeLink"
                value={car.youtubeLink}
                onChange={handleChange}
                placeholder="Enter YouTube link"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>

          {/* Second Column */}
          <div className="space-y-4">
            <div>
              <label
                className="block text-gray-700 mb-2"
                htmlFor="transmission"
              >
                Transmission
              </label>
              <select
                id="transmission"
                name="transmission"
                value={car.transmission}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Select Transmission</option>
                <option value="Automatic">Automatic</option>
                <option value="Manual">Manual</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="gears">
                Gears
              </label>
              <select
                id="gears"
                name="gears"
                value={car.gears}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Select Gears</option>
                {Array.from({ length: 5 }, (_, i) => i + 4).map((gear) => (
                  <option key={gear} value={gear}>
                    {gear}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="mileage">
                Mileage
              </label>
              <input
                type="text"
                id="mileage"
                name="mileage"
                value={car.mileage}
                onChange={(e) => {
                  if (isNumber(e.target.value)) handleChange(e);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="stock">
                Stock
              </label>
              <input
                type="text"
                id="stock"
                name="stock"
                value={car.stock}
                onChange={(e) => {
                  if (isNumber(e.target.value)) handleChange(e);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="year">
                Year
              </label>
              <input
                type="text"
                id="year"
                name="year"
                value={car.year}
                onChange={(e) => {
                  if (isNumber(e.target.value)) handleChange(e);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="color">
                Color
              </label>
              <input
                type="text"
                id="color"
                name="color"
                value={car.color}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="kmsDriven">
                Kms Driven
              </label>
              <input
                type="text"
                id="kmsDriven"
                name="kmsDriven"
                value={car.kmsDriven}
                onChange={(e) => {
                  if (isNumber(e.target.value)) handleChange(e);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="fuelType">
                Fuel Type
              </label>
              <select
                id="fuelType"
                name="fuelType"
                value={car.fuelType}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Select Fuel Type</option>
                <option value="Petrol">Petrol</option>
                <option value="Diesel">Diesel</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700 mb-2" htmlFor="insurance">
                Insurance (Years)
              </label>
              <input
                type="text"
                id="insurance"
                name="insurance"
                value={car.insurance}
                onChange={(e) => {
                  if (isNumber(e.target.value)) handleChange(e);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div>
              <label
                className="block text-gray-700 mb-2"
                htmlFor="numberOfOwners"
              >
                Number of Owners
              </label>
              <input
                type="text"
                id="numberOfOwners"
                name="numberOfOwners"
                value={car.numberOfOwners}
                onChange={(e) => {
                  if (isNumber(e.target.value)) handleChange(e);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div>
              <label className="block text-gray-700 mb-2" htmlFor="negotiable">
                Negotiable
              </label>
              <select
                id="negotiable"
                name="negotiable"
                value={car.negotiable}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Select Option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-700 mb-2" htmlFor="image">
                Image
              </label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleImageChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>

          <div className="col-span-2 flex justify-end mt-6">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-400 text-white px-4 py-2 rounded flex items-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Submitting...
                </>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
        {showSuccess && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg">
              <p className="text-green-500">Car added successfully!</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddCarModal;
