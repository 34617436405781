import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import {
  FaTachometerAlt,
  FaGasPump,
  FaYoutube,
} from "react-icons/fa";
import {
  MdAirlineSeatReclineNormal,
} from "react-icons/md";

const VehicleListing = () => {
  const [carData, setCarData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("Relevance");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  useEffect(() => {
    fetchCarData();
  }, []);

  const fetchCarData = async () => {
    try {
      const carsCollection = collection(db, "cars");
      const carSnapshot = await getDocs(carsCollection);
      const carList = carSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCarData(carList);
    } catch (error) {
      console.error("Error fetching car data: ", error);
    }
  };

  const filteredCars = carData.filter((car) => {
    const matchesSearch = car.name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesPrice =
      (!minPrice || car.price >= parseInt(minPrice)) &&
      (!maxPrice || car.price <= parseInt(maxPrice));
    const matchesYear =
      !selectedYear || (car.year && car.year.toString() === selectedYear);

    return matchesSearch && matchesPrice && matchesYear;
  });

  const sortedCars = [...filteredCars].sort((a, b) => {
    if (sortBy === "Price: Low to High") return a.price - b.price;
    if (sortBy === "Price: High to Low") return b.price - a.price;
    if (sortBy === "Newest First") return b.year - a.year;
    return 0; // Default (Relevance)
  });

  const years = [...new Set(carData.map((car) => car.year))]
    .filter((year) => year !== undefined)
    .sort((a, b) => b - a);

  return (
    <>
      <Navbar />
      <div className="container mx-auto py-10 px-4">
        <nav className="text-sm mb-4">
          <Link to="/" className="text-gray-500">
            Home
          </Link>
          <span className="mx-2">/</span>
          <span className="text-gray-700">Search results</span>
        </nav>

        <h1 className="text-2xl font-bold mb-4">SEARCH RESULTS</h1>

        <div className="mb-6 flex flex-wrap gap-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search cars"
            className="p-2 border border-gray-300 rounded"
          />
          <input
            type="number"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
            placeholder="Min Price"
            className="p-2 border border-gray-300 rounded"
          />
          <input
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            placeholder="Max Price"
            className="p-2 border border-gray-300 rounded"
          />
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="p-2 border border-gray-300 rounded"
          >
            <option value="">All Years</option>
            {years.map((year) => (
              <option key={year} value={year.toString()}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-between items-center mb-6">
          <p>
            There {sortedCars.length === 1 ? "is" : "are"} {sortedCars.length}{" "}
            product{sortedCars.length !== 1 && "s"}.
          </p>
          <div className="flex items-center">
            <label htmlFor="sort" className="mr-2">
              Sort by:
            </label>
            <select
              id="sort"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="p-2 border border-gray-300 rounded"
            >
              <option>Relevance</option>
              <option>Price: Low to High</option>
              <option>Price: High to Low</option>
              <option>Newest First</option>
            </select>
          </div>
        </div>

        <div className="space-y-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sortedCars.map((car) => (
            <div
              key={car.id}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <Link to={`/vehicles/${car.id}`} className="block">
                <img
                  className="w-full h-48 object-cover"
                  src={car.imageUrl}
                  alt={car.name}
                />
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">
                    {car.year} {car.name}
                  </h2>
                  <p className="text-gray-500 mb-2">
                    <span className="text-lg font-bold text-black">₹ {car.price}</span>
                  </p>

                  <div className="flex items-center space-x-2 mb-2">
                    <MdAirlineSeatReclineNormal className="text-gray-500" />
                    <p className="text-gray-500">{car.seats} Seats</p>
                  </div>

                  {car.mileage && (
                    <div className="flex items-center space-x-2 mb-2">
                      <FaTachometerAlt className="text-gray-500" />
                      <p className="text-gray-500">
                        Mileage: {car.mileage} km/l
                      </p>
                    </div>
                  )}

                  {car.fuelType && (
                    <div className="flex items-center space-x-2 mb-2">
                      <FaGasPump className="text-gray-500" />
                      <p className="text-gray-500">Fuel Type: {car.fuelType}</p>
                    </div>
                  )}

                 
                  {car.youtubeLink && (
                    <a
                      href={car.youtubeLink}
                      className="text-blue-500 flex items-center mt-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube className="mr-2" />
                      Watch on YouTube
                    </a>
                  )}
                </div>
              </Link>
            </div>
          ))}
        </div>

        <p className="mt-6 text-sm text-gray-500">
          Showing {sortedCars.length > 0 ? 1 : 0}-{sortedCars.length} of{" "}
          {sortedCars.length} item(s)
        </p>
      </div>
    </>
  );
};

export default VehicleListing;
