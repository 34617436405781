import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "soldcars"));
        const fetchedReviews = [];
        querySnapshot.forEach((doc) => {
          const car = doc.data();
          if (car.reviews && car.reviews.length > 0) {
            car.reviews.forEach((review, index) => {
              fetchedReviews.push({
                id: `${doc.id}-${index}`,
                name: car.name,
                imageUrl:
                  car.happyCustomerImages && car.happyCustomerImages.length > 0
                    ? car.happyCustomerImages[0]
                    : "",
                stars: car.ratings ? car.ratings[index] : 5,
                review: review || "", // Directly use the review string
                videoUrl: car.youtubeLink || "", // Fetch videoUrl if available
                position: "Customer",
              });
            });
          }
        });
        setReviews(fetchedReviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length
    );
  };

  const extractVideoId = (url) => {
    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?&]+)/
    );
    if (videoIdMatch) {
      return videoIdMatch[1];
    }
    return "";
  };

  if (reviews.length === 0) {
    return <div>Loading reviews...</div>;
  }

  return (
    <div className="bg-[#e6f2f2] py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-2">
          <span className="text-[#4cc9f0]">Reviews</span>
        </h2>
        <p className="text-center text-gray-600 mb-12">Testimonials</p>

        <div className="relative overflow-hidden">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {reviews.map((review) => (
              <div key={review.id} className="w-full flex-shrink-0">
                <div className="bg-white rounded-3xl shadow-lg p-8 relative max-w-md mx-auto mt-16">
                  {/* Customer Picture */}
                  <div className="absolute -top-16 left-1/2 transform -translate-x-1/2 w-32 h-32 rounded-full overflow-hidden border-4 border-white z-10">
                    {review.imageUrl ? (
                      <img
                        src={review.imageUrl}
                        alt={review.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                        <span className="text-2xl">{review.name[0]}</span>
                      </div>
                    )}
                  </div>
                  {/* Review Content */}
                  <p className="text-center mt-16 mb-4 text-gray-600">
                    "{review.review}"
                  </p>
                  <div className="text-center">
                    <h3 className="font-semibold text-lg">{review.name}</h3>
                    <p className="text-sm text-gray-500">{review.position}</p>
                    {/* Star Ratings */}
                    <div className="flex justify-center items-center mt-2">
                      <span className="bg-[#4cc9f0] text-white px-3 py-1 rounded-full text-sm font-bold">
                        {review.stars}.0
                      </span>
                      <div className="flex ml-2">
                        {[...Array(5)].map((_, i) => (
                          <svg
                            key={i}
                            className={`w-4 h-4 ${
                              i < review.stars
                                ? "text-[#4cc9f0]"
                                : "text-gray-300"
                            }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        ))}
                      </div>
                    </div>
                    {/* Car Name */}
                    <p className="text-sm font-medium mt-2">
                      Car: {review.name}
                    </p>
                    {/* YouTube Video */}
                    {review.videoUrl && (
                      <div className="mt-4 flex justify-center">
                        <iframe
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${extractVideoId(
                            review.videoUrl
                          )}`}
                          title="Customer Review Video"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="rounded-md"
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Navigation Buttons */}
          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
        {/* Dots Navigation */}
        <div className="flex justify-center mt-8">
          {reviews.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full mx-1 ${
                index === currentIndex ? "bg-[#4cc9f0]" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
