import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";

const SoldCarModal = ({ car, onClose, onSave }) => {
  const updatedCar = { ...car };
  const [newReview, setNewReview] = useState("");
  const [selectedRating, setSelectedRating] = useState(
    car.ratings && car.ratings.length > 0 ? car.ratings[0] : 5
  );
  const [newImage, setNewImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const storage = getStorage();


  // Handle rating dropdown changes
  const handleRatingChange = (e) => {
    setSelectedRating(Number(e.target.value));
  };

  // Handle review input changes
  const handleReviewChange = (e) => {
    setNewReview(e.target.value);
  };

  // Handle image file input changes
  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };

  // Upload image to Firebase Storage
  const uploadImage = async (imageFile) => {
    const imageRef = ref(
      storage,
      `happyCustomerImages/${Date.now()}_${imageFile.name}`
    );
    try {
      await uploadBytes(imageRef, imageFile);
      const imageUrl = await getDownloadURL(imageRef);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  // Save updated car details
  const handleSave = async () => {
    setUploading(true);

    // Upload image if new image is selected
    let newImageUrl = null;
    if (newImage) {
      newImageUrl = await uploadImage(newImage);
    }

    // Prepare the updated data
    const updatedData = {
      ...updatedCar,
      ratings: [selectedRating], // Replace existing ratings with the new one
      reviews: [newReview], // Replace existing reviews with the new one
      happyCustomerImages: newImageUrl
        ? [newImageUrl]
        : updatedCar.happyCustomerImages || [], // Replace existing images with the new one if available
    };

    try {
      const carDocRef = doc(db, "soldcars", car.id);
      await updateDoc(carDocRef, updatedData);
      onSave(updatedData);
      onClose();
    } catch (error) {
      console.error("Error saving car details:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Car Details</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {/* Reviews Section */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Reviews
          </label>
          <div className="mt-1">
            {updatedCar.reviews && updatedCar.reviews.length > 0 ? (
              updatedCar.reviews.map((review, index) => (
                <p key={index} className="text-sm text-gray-600">
                  "{typeof review === "string" ? review : review.text || ""}"
                </p>
              ))
            ) : (
              <p className="text-sm text-gray-600">
                Add reviews below to see them listed here.
              </p>
            )}
          </div>
        </div>

        {/* Add Review Section */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Add New Review
          </label>
          <textarea
            value={newReview}
            onChange={handleReviewChange}
            placeholder="Add a new review"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Rating Section */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Rating
          </label>
          <select
            name="rating"
            value={selectedRating}
            onChange={handleRatingChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
          >
            {[1, 2, 3, 4, 5].map((rating) => (
              <option key={rating} value={rating}>
                {rating} Star{rating > 1 ? "s" : ""}
              </option>
            ))}
          </select>
        </div>

        {/* Image File Input Section */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Upload Happy Customer Image
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Display Existing Images */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Happy Customer Images
          </label>
          <div className="mt-1 flex space-x-2">
            {updatedCar.happyCustomerImages &&
            updatedCar.happyCustomerImages.length > 0 ? (
              updatedCar.happyCustomerImages.map((image, index) => (
                <img
                  key={index}
                  src={image} // Ensure image is a URL or valid image path
                  alt={`Happy Customer ${index + 1}`}
                  className="w-32 h-32 object-cover rounded-full border border-gray-300"
                />
              ))
            ) : (
              <p className="text-sm text-gray-600">
                No images available. Upload an image to display here.
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            disabled={uploading}
          >
            {uploading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SoldCarModal;
